// src/components/Editor.js
import React, { useState, useEffect, useRef, forwardRef } from "react";
import { Box, Text } from "@chakra-ui/react";

const Editor = forwardRef(
  (
    {
      specialChar,
      scale,
      isPreviewMode,
      isTransitioning,
      editorRef,
      fetchedContent,
    },
    ref
  ) => {
    const [lineNumbers, setLineNumbers] = useState([]);

    useEffect(() => {
      if (specialChar && editorRef.current) {
        const selection = window.getSelection();

        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0); // 현재 선택된 범위(커서 위치) 가져오기

          // 특수문자를 현재 커서 위치에 삽입
          const textNode = document.createTextNode(specialChar);
          range.insertNode(textNode);

          // 삽입 후 커서를 특수문자 뒤로 이동
          range.setStartAfter(textNode);
          range.setEndAfter(textNode);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }, [specialChar]);

    const lineHeight = 24; // 고정된 줄 높이 (px 단위)
    const pageLineCounts = [30, 34, 34, 34]; // 4페이지마다 줄 수 반복

    const calculateVisibleLines = () => {
      if (editorRef.current) {
        const editorHeight = editorRef.current.scrollHeight; // 전체 텍스트의 높이
        const totalLines = Math.floor(editorHeight / lineHeight); // 전체 텍스트의 줄 수 계산
        const newLineNumbers = [];

        let currentLine = 1;
        let currentPage = 1;
        let currentPageLine = 0;
        let currentPageMaxLines = pageLineCounts[(currentPage - 1) % 4]; // 첫 페이지는 30줄

        for (let i = 0; i < totalLines; i++) {
          if (currentPageLine >= currentPageMaxLines) {
            // 다음 페이지로 넘어가기
            currentPage++;
            currentPageLine = 0;
            currentPageMaxLines = pageLineCounts[(currentPage - 1) % 4]; // 다음 페이지의 줄 수
          }
          currentPageLine++;
          newLineNumbers.push({
            page: currentPage,
            line: currentPageLine,
          });
        }

        setLineNumbers(newLineNumbers);
      }
    };

    // 텍스트가 변경될 때마다 줄 계산 실행
    useEffect(() => {
      calculateVisibleLines();
    }, []);

    useEffect(() => {
      if (fetchedContent && editorRef.current) {
        // Fetch한 데이터를 editorRef에 삽입
        editorRef.current.innerText = fetchedContent;
      }
    }, [fetchedContent]);

    return (
      <Box>
        <Box height="100px" />
        <Box
          ref={ref}
          sx={{
            "@media print": {
              "@page": {
                paddingTop: "300mm", // 페이지 상단 마진 설정
                marginBottom: "15mm", // 페이지 하단 마진 설정
              },
              transform: "scale(0.95)",
              fontSize: "16px", // 프린트할 때 고정된 폰트 크기
              lineHeight: "1.5", // 적절한 줄 간격
            },
          }}
          display="flex"
          borderRadius="15px"
          transform={`scale(${scale})`} // scale 값을 적용
          transformOrigin="center 0" // 좌측 상단을 기준으로 확대/축소
          transition="transform 0.2s ease"
        >
          <Box width="40px">
            {/* <Box height="48px" width="12px" /> */}
            {lineNumbers.map((line, index) => (
              <Text
                key={index}
                color={index % 2 === 0 ? "gray.500" : "gray.100"}
                lineHeight="24px"
                fontSize="11px"
              >
                {line.line} {line.line === 1 && `(${line.page}쪽)`}
              </Text>
            ))}
          </Box>
          <Box>
            {/* <Box
          height="24px"
          backgroundColor="#05418F"
          borderRadius="10px 10px 0px 0px"
        >
          <Text color="white">시험과목</Text>
        </Box>
        <Box height="24px" backgroundColor="#C2D9FA">
          <Text color="#05418F">연습문제</Text>
        </Box> */}

            <Box
              className="page-wrapper"
              width="786px"
              height="7872px" // 페이지 높이 설정
              mx="auto" // 중앙 정렬
              border="1px"
              borderColor="gray.300"
              backgroundImage={`repeating-linear-gradient(
        rgba(247, 247, 247, 0.8),
        rgba(247, 247, 247, 0.8) 720px,
        rgba(227, 235, 241, 0.8) 720px,
        rgba(227, 235, 241, 0.8) 1536px,
        rgba(247, 247, 247, 0.8) 1536px,
        rgba(247, 247, 247, 0.8) 2352px,
        rgba(227, 235, 241, 0.8) 2352px,
        rgba(227, 235, 241, 0.8) 3168px
      ),
      repeating-linear-gradient(
        transparent,
        transparent 23px,
        rgb(25, 22, 43) 23px,
        rgb(25, 22, 43) 24px
      )`}
              //   backgroundSize="100% 32px" // 줄 간격 조정
            >
              <Box
                contentEditable={!isPreviewMode}
                spellCheck="false"
                ref={editorRef}
                onInput={calculateVisibleLines}
                className="page editor"
                minHeight="7872px"
                fontFamily="Malgun Gothic" // 정확한 폰트 설정
                fontSize="16px"
                lineHeight={`${lineHeight}px`} // 줄 높이 설정
                whiteSpace="pre-wrap"
                outline="none"
                transition="opacity 0.3s ease, transform 0.2s ease" // 전환 효과 추가
                opacity={isTransitioning ? 0 : 1} // 전환 중일 때는 투명도 0
                style={{
                  "-webkit-font-smoothing": "antialiased", // 크롬 및 사파리 폰트 렌더링 개선
                  "font-smoothing": "antialiased", // 추가 폰트 스무딩 적용
                }}
              >
                <div>
                  <br />
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box height="100px" />
      </Box>
    );
  }
);

export default Editor;
