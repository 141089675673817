import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import ReactToPrint from "react-to-print";
import { useNavigate } from "react-router-dom";
import { uploadAnswer, modifyAnswer } from "../api";

const Controls = ({
  togglePreviewMode,
  isPreviewMode,
  textRef,
  editorRef,
  subject,
  editVersion,
  answer_id,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure(); // 모달 열기/닫기 상태 관리
  const navigate = useNavigate(); // 페이지 이동을 위한 훅
  const [isSaved, setIsSaved] = useState(false); // 저장 여부 관리

  const saveAnswer = async () => {
    const content = textRef.current.innerText; // 에디터 내용 가져오기

    if (!content) {
      console.log(content);
      console.error("제목과 내용을 입력해주세요.");
      return;
    }
    if (editVersion) {
      let response = await modifyAnswer(answer_id, content);
      setIsSaved(true);
      onOpen(); // 저장 후 모달 열기

      if (!response.ok) {
        throw new Error("Failed to update the answer");
      }

      const data = await response.json();
      return data; // Return the updated answer data
    } else {
      let response = await uploadAnswer(subject, content);

      if (response.ok) {
        const data = await response.json();
        console.log("답안 저장 성공:", data);
        setIsSaved(true);
        onOpen(); // 저장 후 모달 열기
      } else {
        console.error("답안 저장 실패");
      }
    }
  };

  const handleMainPageRedirect = () => {
    navigate("/answers");
  };
  const isLoggedIn = !!localStorage.getItem("access_token"); // 로그인 여부 확인

  return (
    <>
      {/* 미리보기 버튼 */}
      <Button
        onClick={togglePreviewMode}
        colorScheme="blue"
        mr="2"
        height="40px"
      >
        {isPreviewMode ? "편집 모드로 돌아가기" : "답안 미리보기"}
      </Button>
      {/* 답안 저장 버튼 */}
      {!isLoggedIn ? (
        <ReactToPrint
          trigger={() => (
            <Button colorScheme="teal" mr="3">
              답안 프린트하기
            </Button>
          )}
          content={() => editorRef.current}
        />
      ) : (
        <>
          <Button colorScheme="green" height="40px" onClick={saveAnswer}>
            답안 저장
          </Button>

          {/* 모달 */}
          <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>답안이 저장되었습니다.</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>답안이 성공적으로 저장되었습니다.</Text>
              </ModalBody>
              <ModalFooter>
                {/* 프린트 버튼 */}
                <ReactToPrint
                  trigger={() => (
                    <Button colorScheme="blue" mr="3">
                      답안 프린트하기
                    </Button>
                  )}
                  content={() => editorRef.current}
                />

                {/* 메인 화면으로 돌아가기 버튼 */}
                <Button colorScheme="teal" onClick={handleMainPageRedirect}>
                  메인 화면으로 돌아가기
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
};

export default Controls;
